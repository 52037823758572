<template>
    <div class="container-fluid">
        <div class="card my-3">
            <div class="card-header d-inline-flex justify-content-between">
                <AdminSideBarToggle/>
                <el-input
                    style="max-width: 250px"
                    class="ms-auto me-4"
                    clearable
                    v-model="searchText"
                    prefix-icon="el-icon-search"
                    :placeholder="$t('search')"/>
                <el-button
                    @click="showLicenseCreateDialog"
                    size="small"
                    type="primary">{{ $t('add') }}</el-button>
            </div>
            <!-- Licenses table -->
            <div class="card-body">
                <el-table
                    v-loading="licensesLoadingState"
                    :data="filteredLicenses"
                    style="width: 100%">
                    <el-table-column
                        prop="user.email"
                        fixed="left"
                        min-width="150px"
                        label="Email">
                    </el-table-column>
                    <el-table-column
                        prop="account.number"
                        min-width="150px"
                        :label="$t('account')">
                    </el-table-column>
                    <el-table-column
                        prop="account.name"
                        min-width="150px"
                        :label="$t('name')">
                    </el-table-column>
                    <el-table-column
                        prop="license"
                        min-width="150px"
                        :label="$t('license')">
                    </el-table-column>
                    <el-table-column
                        prop="created_at"
                        min-width="150px"
                        :formatter="dateTimeFormatter"
                        :label="$t('createdAt')"/>
                    <el-table-column
                        prop="paid_till"
                        min-width="150px"
                        :formatter="dateTimeFormatter"
                        :label="$t('paidTill')">
                    </el-table-column>
                    <el-table-column
                        prop="state"
                        min-width="150px"
                        :formatter="stateFormatter"
                        :label="$t('state')">
                    </el-table-column>
                    <el-table-column
                        prop="risk"
                        min-width="150px"
                        :formatter="riskFormatter"
                        :label="$t('risk')">
                    </el-table-column>
                    <el-table-column
                        prop="recommendations"
                        min-width="150px"
                        :formatter="recommendationsFormatter"
                        :label="$t('recommendations')">
                    </el-table-column>
                    <el-table-column
                        min-width="140px"
                        :label="$t('actions')">
                        <template slot-scope="scope">
                            <el-button
                                size="small"
                                type="primary"
                                @click="showLicenseEditDialog(scope.row)">{{ $t('edit') }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- User edit dialog -->
        <el-dialog
            class="dialog"
            :title="licenseForm.license_id ? $t('licenseEditing') : $t('licenseAdding')"
            :visible.sync="isLicenseEditDialogVisible"
            >
            <el-form
                @submit.native.prevent
                label-position="left"
                ref="licenseForm"
                :rules="licenseFormRules"
                :model="licenseForm"
                label-width="150px">
                <el-form-item label="Email" prop="user_id">
                    <el-select
                        :disabled="!!licenseForm.license_id"
                        v-model="licenseForm.user_id"
                        :placeholder="$t('user')">
                        <el-option
                            v-for="user in this.users"
                            :key="user.user_id"
                            :label="user.email"
                            :value="user.user_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('license')" prop="license">
                    <el-input
                        clearable
                        :disabled="!!licenseForm.license_id"
                        v-model="licenseForm.license"
                        :placeholder="$t('license')">
                        <el-button
                            @click="generateNewLicense"
                            :disabled="!!licenseForm.license_id"
                            slot="append"
                            icon="el-icon-refresh-right"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item :label="$t('paidTill')" prop="paid_till">
                    <el-date-picker
                        v-model="licenseForm.paid_till"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm:ss"
                        value-format="yyyy-MM-ddTHH:mm:ss"
                        :placeholder="$t('paidTill')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$t('state')" prop="state">
                    <el-switch
                        v-model="licenseForm.state"
                        :active-value="1"
                        :inactive-value="0"/>
                </el-form-item>
                <el-form-item :label="$t('recommendations')" prop="recommendations">
                    <el-switch
                        v-model="licenseForm.recommendations"
                        :active-value="1"
                        :inactive-value="0"/>
                </el-form-item>
                <el-form-item :label="$t('risk')" prop="risk">
                    <el-select
                        v-model="licenseForm.risk"
                        :placeholder="$t('risk')">
                        <el-option :label="$t('cautious')" :value="0"/>
                        <el-option :label="$t('classic')" :value="1"/>
                        <el-option :label="$t('aggressive')" :value="2"/>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="isLicenseEditDialogVisible = false">{{ $t('close') }}</el-button>
                <el-button size="small" type="primary" @click="submitLicenseForm" :loading="licensesLoadingState">{{ $t('save') }}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {dateTimeToString, generateRandomString} from '@/common/helpers'
import AdminSideBarToggle from '@/components/AdminSideBarToggle'
/*
    TODO: error messages
 */
export default {
    name: "Licenses",
    components: {
        AdminSideBarToggle,
    },
    i18n: {
        messages: {
            en: {
                // Table
                account: 'Account',
                name: 'Name',
                license: 'License',
                createdAt: 'Created at',
                paidTill: 'Paid till',
                state: 'State',
                risk: 'Risk level',
                recommendations: 'Recommendations',
                actions: 'Actions',

                licenses: 'Licenses',
                search: 'Search',
                add: 'Add',
                edit: 'Edit',
                close: 'Close',
                save: 'Save',
                licenseEditing: 'Editing a license',
                licenseAdding: 'Adding a license',
                user: 'User',
                on: 'On',
                off: 'Off',

                cautious: 'Cautious',
                classic: 'Classic',
                aggressive: 'Aggressive',

                thisFieldIsRequired: 'This field is required',
                licenseLengthError: 'License must be exactly 42 characters',

                error: 'Error',
                success: 'Success',
                licenseUpdated: 'License updated',
                licenseCreated: 'License created',
                licenseUpdateError: 'License update error',
                licenseCreateError: 'License create error',
                errorLoadingLicenses: 'Licenses loading error',
            },
            ru: {
                // Table
                account: 'Счет',
                name: 'Имя',
                license: 'Лицензия',
                createdAt: 'Создана',
                paidTill: 'Оплачена до',
                state: 'Состояние',
                risk: 'Уровень риска',
                recommendations: 'Рекоммендации',
                actions: 'Действия',

                licenses: 'Лицензии',
                search: 'Поиск',
                add: 'Добавить',
                edit: 'Изменить',
                close: 'Закрыть',
                save: 'Сохранить',
                licenseEditing: 'Редактирование лицензии',
                licenseAdding: 'Добавление лицензии',
                user: 'Пользователь',

                on: 'Включено',
                off: 'Выключено',

                cautious: 'Осторожный',
                classic: 'Классический',
                aggressive: 'Агрессивный',

                thisFieldIsRequired: 'Поле обязательно для заполнения',
                licenseLengthError: 'Лицензия должна состоять из 42 символов',

                error: 'Ошибка',
                success: 'Успешно',
                licenseUpdated: 'Лицензия обновлена',
                licenseCreated: 'Лицензия создана',
                licenseUpdateError: 'Ошибка обновления лицензии',
                licenseCreateError: 'Ошибка создания лицензии',
                errorLoadingLicenses: 'Ошибка загрузки лицензий',
            }
        }
    },
    data: function () {
        return {
            isLicenseEditDialogVisible: false,
            searchText: '',
            licenseForm: {
                license_id: null,
                user_id: null,
                license: '',
                paid_till: null,
                state: 0,
                risk: 0,
                recommendations: 1,
            },
            licenseFormRules: {
                user_id: [{
                    required: true,
                    message: () => this.$t('thisFieldIsRequired')
                }],
                license: [{
                    required: true,
                    len: 42,
                    message: () => this.$t('licenseLengthError')
                }],
                paid_till: [{
                    required: true,
                    message: () => this.$t('thisFieldIsRequired')
                }],
                state: [{
                    required: true,
                    message: () => this.$t('thisFieldIsRequired')
                }],
                risk: [{
                    required: true,
                    message: () => this.$t('thisFieldIsRequired')
                }],
                recommendations: [{
                    required: true,
                    message: () => this.$t('thisFieldIsRequired')
                }]
            }
        }
    },
    computed: {
        licenses: function () {
            return this.$store.getters['admin/licenses']
        },
        licensesLoadingState: function () {
            return this.$store.getters['admin/licensesLoadingState']
        },
        filteredLicenses: function () {
            return this.licenses.filter(license => {
                return license.user.email.toUpperCase().includes(this.searchText.toUpperCase()) || license.license.includes(this.searchText)
            })
        },
        users: function () {
            return this.$store.getters['admin/users']
        }
    },
    methods: {
        dateTimeFormatter: function (row, column, cellValue) {
            return dateTimeToString(cellValue)
        },
        stateFormatter: function (row, column, cellValue) {
            return cellValue === 1 ? this.$t('on') : this.$t('off')
        },
        riskFormatter: function (row, column, cellValue) {
            switch (cellValue) {
                case 0:
                    return this.$t('cautious')
                case 1:
                    return this.$t('classic')
                case 2:
                    return this.$t('aggressive')
                default:
                    return cellValue
            }
        },
        recommendationsFormatter: function (row, column, cellValue) {
            return cellValue === 1 ? this.$t('on') : this.$t('off')
        },
        reloadLicenses: function () {
            this.$store.dispatch('admin/licensesGetRequest')
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                        message: this.$t('errorLoadingLicenses'),
                    })
                })
        },
        reloadUsers: function () {
            this.$store.dispatch('admin/usersGetRequest')
                .catch(() => {
                    this.$notify({
                        type: 'error',
                        title: this.$t('error'),
                        message: this.$t('errorLoadingUsers'),
                    })
                })
        },
        showLicenseCreateDialog: function () {
            // Prepare data
            this.licenseForm.license_id = null
            this.licenseForm.user_id = null
            this.licenseForm.license = ''
            this.licenseForm.state = 1
            this.licenseForm.risk = 0
            this.licenseForm.recommendations = 1
            this.licenseForm.paid_till = new Date()
            this.generateNewLicense()
            // Show dialog
            this.isLicenseEditDialogVisible = true
        },
        showLicenseEditDialog: function (license) {
            // Prepare data
            this.licenseForm.license_id = license.license_id
            this.licenseForm.user_id = license.user_id
            this.licenseForm.license = license.license
            this.licenseForm.state = license.state
            this.licenseForm.risk = license.risk
            this.licenseForm.recommendations = license.recommendations
            this.licenseForm.paid_till = license.paid_till

            // Show dialog
            this.isLicenseEditDialogVisible = true
        },
        submitLicenseForm: function () {
            this.$refs['licenseForm'].validate(valid => {
                if(!valid) {
                    return false
                }
                if(this.licenseForm.license_id) {
                    // Update license
                    this.$store.dispatch('admin/licenseUpdateRequest', this.licenseForm)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: this.$t('success'),
                            message: this.$t('licenseUpdated')
                        })
                        this.isLicenseEditDialogVisible = false
                    })
                    .catch((e) => {
                        console.log(e)
                        this.$notify({
                            type: 'error',
                            title: this.$t('error'),
                            message: this.$t('licenseUpdateError'),
                        })
                    })
                } else {
                    // Create new license
                    this.$store.dispatch('admin/licenseCreateRequest', this.licenseForm)
                        .then(() => {
                            this.$notify({
                                type: 'success',
                                title: this.$t('success'),
                                message: this.$t('licenseCreated')
                            })
                            this.isLicenseEditDialogVisible = false
                        })
                        .catch((e) => {
                            console.log(e)
                            this.$notify({
                                type: 'error',
                                title: this.$t('error'),
                                message: this.$t('licenseCreateError'),
                            })
                        })
                }
            })
        },
        generateNewLicense: function () {
            this.licenseForm.license = generateRandomString(42)
        }
    },
    mounted() {
        this.reloadLicenses()
        this.reloadUsers()
    }
}
</script>

<style scoped>
@media (max-width: 576px) {
    .dialog >>> .el-dialog {
        width: 100%!important;
    }
}
</style>
